var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "operation_main" }, [
    _c("div", { staticClass: "operation_content" }, [
      _c("div", { staticClass: "ocr_picture_box" }, [
        _c("div", { staticClass: "picture_preview" }, [
          _c("img", {
            attrs: {
              src:
                _vm.imgList && _vm.imgList.length > 0
                  ? _vm.imgList[_vm.imgIndex]
                  : "",
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "picture_list_select" }, [
          _c(
            "div",
            { ref: "picScrollDiv", staticClass: "picture_item_list" },
            _vm._l(_vm.imgList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  class:
                    _vm.imgIndex === index
                      ? "picture_item_select"
                      : "picture_item",
                  on: {
                    click: function($event) {
                      return _vm.selectPic(index)
                    }
                  }
                },
                [_c("img", { attrs: { src: item, alt: "" } })]
              )
            }),
            0
          ),
          _c("div", { staticClass: "right_arrow" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/right_arrow.png"),
                alt: ""
              },
              on: { click: _vm.scrollDiv }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "ocr_result_box" }, [
        _c("div", { staticClass: "box_container" }, [
          _vm._m(0),
          _vm.status === 0
            ? _c("div", { staticClass: "ocr_info_success" }, [
                _c("div", { staticClass: "ocr_table" }, [
                  _c("div", { staticClass: "table_title" }, [
                    _c("span", [_vm._v(_vm._s(this.$route.params.fileName))])
                  ]),
                  _c(
                    "div",
                    { staticClass: "table_body" },
                    _vm._l(_vm.resultList, function(item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "table_row_item" },
                        [
                          _c("div", { staticClass: "table_row_left" }, [
                            _c("input", {
                              staticClass: "left",
                              attrs: { type: "text" },
                              domProps: { value: item.word_name }
                            })
                          ]),
                          _c("div", { staticClass: "table_row_right" }, [
                            _c("input", {
                              staticClass: "right",
                              attrs: { type: "text" },
                              domProps: { value: item.word }
                            })
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            : _vm.status === 1
            ? _c("div", { staticClass: "ocr_info_underway" }, [_vm._m(1)])
            : _vm.status === 2
            ? _c("div", { staticClass: "ocr_info_fail" }, [_vm._m(2)])
            : _vm._e()
        ])
      ])
    ]),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "ocr_result_title" }, [
      _c("span", [_vm._v("识别结果")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "underway" }, [
      _c("span", [_vm._v("正在识别,请稍等 !")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "fail" }, [
      _c("span", [_vm._v("对不起,识别失败,请重新上传 !")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "btn_back" }, [_vm._v("返回")]),
      _c("div", { staticClass: "btn_edit" }, [_vm._v("编辑保存")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }