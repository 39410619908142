
import { Component, Vue } from 'vue-property-decorator'
import { pdfToImg, scanWord } from '@/api/filing'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {},
  filters: {}
})
export default class Operation extends Vue {
  private loadingTxt: string = loadingText
  private imgList: any = []
  private resultList: any = []
  private status: any = 1
  private imgIndex: number = 0
  private isOcrEnd: boolean = false

  created() {
    this.getPdfImgList()
  }

  selectPic(index: any) {
    if (this.isOcrEnd) {
      this.isOcrEnd = false
      this.imgIndex = index
      this.status = 1
      this.scanWord(this.imgList[index])
    } else {
      this.$message.warning('正在识别中，请耐心等待')
    }
  }

  scanWord(picUrl: any) {
    const params = {
      url: picUrl,
      templateSign: 'trusteeApplicationMemership'
    }
    scanWord(params as any)
      .then((res) => {
        if (res.data.data.data) {
          this.status = 0
          this.resultList = res.data.data.data.ret
        }
      })
      .catch((e) => {
        this.status = 2
      })
      .finally(() => {
        this.isOcrEnd = true
      })
  }

  getPdfImgList() {
    this.$loading({
      fullscreen: true,
      text: this.loadingTxt
    })
    const params = {
      // url: this.$route.params.fileAddr
      url: 'http://fdfs.zct.gjsc.info/group1/M00/00/31/wKgECl9xi-mAf2VQAAsHhUoYgiY927.pdf'
    }
    pdfToImg(params as any)
      .then((res) => {
        if (res.data.data) {
          this.imgList = res.data.data
          if (this.imgList && this.imgList.length > 0) {
            this.scanWord(this.imgList[0])
          }
        }
      })
      .finally(() => {
        this.$loading({ fullscreen: true }).close()
      })
  }

  scrollDiv() {
    let div: any = this.$refs.picScrollDiv
    div.scrollLeft += 120
  }
}
